import MomentUtils from "@date-io/moment";
import { Grid, Hidden, makeStyles, MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, {  useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { Switch } from "react-router-dom";
import { pendingStudents as pendingStudentsAPI } from "./api";
import "./App.css";
import CenterSelection from "./components/Admin/CenterSelection";
import Nav from "./components/Navigation/Nav";
import NavMobil from "./components/Navigation/NavMobile";
import RoutesAdmin from "./components/Routes/RoutesAdmin";
import RoutesCenter from "./components/Routes/RoutesCenter";
import RoutesStudent from "./components/Routes/RoutesStudent";
import RoutesSupervisor from "./components/Routes/RoutesSupervisor";
import RoutesTeacher from "./components/Routes/RoutesTeacher";
import {  useAuth } from "./contexts/AuthProvider/AuthProvider";
import "./react_dates_overrides.css";
import { theme } from "./themes/themes";
import RoutesPublic from "./components/Routes/RoutesPublic";
// import Maintenance from "./components/Maintenance/Maintenance";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "600px",
    display: "flex",
  },
  dockedPaper: {
    borderRight: 0,
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: "14rem",
      flexShrink: 0,
    },
    [theme.breakpoints.down("md")]: {
      width: "5rem",
      flexShrink: 0,
    },
  },
  mobile: {
    width: "100%",
  },
  active: {
    color: "#00b3e6",
  },
  icon: {
    minWidth: 35,
    marginRight: 10,
  },
  title: {
    flexGrow: 1,
  },
  fontAwesomeIcon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    flexShrink: 0,
    userSelect: "none",
    minWidth: 35,
    marginRight: 10,
  },
  contentShift: {
    flexGrow: 1,
    marginLeft: 0,
  },
  search: {
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: 500,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 400,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const queryClient = new QueryClient();

function App() {
  const classes = useStyles();
  const { user, logOut, reloadUser, checkingToken } = useAuth();
  const [selectedCenter, setSelectedCenter] = useState(2);
  const [pendingStudents, setpendingStudents] = useState(0);

  const isAdmin = user.role === "ROLE_ADMIN" && true;

  const fetchPendingStudents = (id) => {
    pendingStudentsAPI.getAll({ centerID: id }).then((result) => {
      setpendingStudents(result.totalCount);
    });
  };

  const fetchCenter = (id) => {
    setSelectedCenter(id);
  };
  if (checkingToken === true) {
    return <div></div>;
  }
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <QueryClientProvider client={queryClient}>
          <div
            style={{ backgroundColor: "rgb(238, 242, 246)", display: "flex" }}
          >
            {user ? (
              <Hidden smDown>
                <nav className={classes.drawer}>
                  <Nav
                    user={user}
                    logOut={logOut}
                    pendingStudents={pendingStudents}
                    fetchPendingStudents={fetchPendingStudents}
                  ></Nav>
                </nav>
              </Hidden>
            ) : null}

            <Grid container>
              {user ? (
                <Grid item xs={12} className={classes.mobile}>
                  <Hidden mdUp>
                    <NavMobil
                      user={user}
                      logOut={logOut}
                      pendingStudents={pendingStudents}
                      fetchPendingStudents={fetchPendingStudents}
                    />
                  </Hidden>
                </Grid>
              ) : null}

              {isAdmin && (
                <CenterSelection fetchCenter={fetchCenter}></CenterSelection>
              )}

              <main className={classes.contentShift}>
                {/* <Maintenance /> */}
                {user === "" ? (
                 <RoutesPublic
                 user={user}
                 reloadUser={reloadUser}
               ></RoutesPublic>
                ) : (
                  <Switch>
                    {user.role === "ROLE_ADMIN" ? (
                      <RoutesAdmin
                        isAdmin={isAdmin}
                        selectedCenter={selectedCenter}
                        user={user}
                        reloadUser={reloadUser}
                      ></RoutesAdmin>
                    ) : null}
                    {user.role === "ROLE_CENTER" ? (
                      <RoutesCenter
                        user={user}
                        reloadUser={reloadUser}
                        fetchPendingStudents={fetchPendingStudents}
                      ></RoutesCenter>
                    ) : null}
                    {user.role === "ROLE_STUDENT" ? (
                      <RoutesStudent
                        user={user}
                        reloadUser={reloadUser}
                      ></RoutesStudent>
                    ) : null}
                    {user.role === "ROLE_TEACHER" ? (
                      <RoutesTeacher
                        user={user}
                        reloadUser={reloadUser}
                      ></RoutesTeacher>
                    ) : null}
                    {user.role === "ROLE_SUPERVISOR" ? (
                      <RoutesSupervisor
                        user={user}
                        reloadUser={reloadUser}
                      ></RoutesSupervisor>
                    ) : null}
                  </Switch>
                )}
              </main>
            </Grid>
          </div>
        </QueryClientProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default App;
